import React from 'react'
import "./cprivacy.css";
import Navbar from "../../Components/Navbar/Navbar"
import Footer from "../../Components/Footer/Footer"
import { Link } from 'react-router-dom';
const Cprivacy = () => {
    return (
        <div>
            <Navbar />
            <section className='california'>
                <div className='container'>
                    <h2>
                        California Privacy Notice
                    </h2>
                    <p>This California Privacy Notice (“Notice”) describes how AccidentMATE uses and discloses the Personal Information we collect from or about California residents.</p>
                    <p>This Notice applies to visitors, users, and others who reside in California and who use our websites, portals, and software, or otherwise interact with us online or offline (our “Services”). Before sharing your Personal Information with us or using our Services, please review this Notice carefully.</p>
                    <p>This Notice supplements the information contained in the privacy policies that we post on our Services (our “Privacy Policies”). Please review these Privacy Policies for general information about how we use and share the Personal Information we collect from or about you when you use the Services.</p>

                    <h5 className='fw-semibold'>1. WHAT IS AccidentMATE?</h5>
                    <p>Throughout this Notice, “AccidentMATE” refers to AccidentMATE website & platform, owned by Pranava Inc, including its affiliated companies and subsidiaries (also referred to as “we” and “us”). AccidentMATE primarily provides services to businesses, and we may collect Personal Information in the context of providing these services. As between AccidentMATE and our business customers, our business customers are primarily responsible for how we use and disclose the Personal Information we collect in our role as a service provider. If AccidentMATE maintains your Personal Information on behalf of one of our business customers, and you have questions about how we process your Personal Information, we may direct any inquiries about our use of your Personal Information to that customer.</p>

                    <h5 className='fw-semibold'>2. PERSONAL INFORMATION WE COLLECT</h5>
                    <p>We may collect information that identifies, relates to, describes, is capable of being associated with,or could reasonably be linked, directly or indirectly, with you or your household (“Personal Information”). Our Privacy Policies contain a general description of the types of Personal Information we collect. Specifically, within the last twelve (12) months, we have collected the following categories of Personal Information:</p>

                    <ul>
                        <li> <p>Direct Identifiers, such as your name, email address, mailing address, IP address, online identifiers, and account information. We typically collect this information directly from you in order to provide you access to certain information on our websites.</p></li>
                        <li> <p>Internet Activity Information, such as your browsing history, search history, and browser information. We typically collect this information from our use of cookies and other data collection technologies to help us design our website, to identify popular features, and for other managerial purposes. </p></li>
                        <li> <p>Commercial Information, such as products and services purchased from us. We typically collect this information directly from you in order to fulfill your transactions and provide you customer service. </p></li>
                        <li> <p>Profile Information, such as information about your preferences and characteristics. We typically collect this information directly from you in order to tailor our services and communications to you. </p></li>
                        <br />
                        <p>In addition to the purposes for collection described above, we have collected each of the above categories of Personal Information for the purpose of performing services for you and managing our relationship with you, which typically includes:</p>
                        <li> <p>Maintaining and servicing your account, including managing your preferences. </p></li>
                        <li> <p> Administering and improving our Services, including to run analytics, assess the quality of our Services, and for other related internal business purposes.</p></li>
                        <li> <p>Communicating with you and responding to inquiries you send to us. </p></li>
                        <li> <p>Sending you messages promoting our products and services. </p></li>
                        <li> <p> Complying with our legal, regulatory and risk management obligations, including establishing, exercising and/or defending legal claims.</p></li>
                    </ul>

                    <h5 className='fw-semibold'>3. HOW WE MAY SHARE AND SELL YOUR PERSONAL INFORMATION</h5>
                    <p>We may share the categories of Personal Information we collect with third parties as described in our Privacy Policies. Specifically, in the preceding twelve (12) months, we have disclosed the following categories of Personal Information to third parties: Direct Identifiers;Internet Activity Information;Commercial Information; and Profile Information.</p>
                    <p>We do not sell your Personal Information. If we change our practices, we will update this Notice in accordance with Section 6.</p>

                    <h5 className='fw-semibold'>4. PRIVACY RIGHTS</h5>
                    <p>You may be entitled to the following privacy rights under California law:</p>
                    <ul>
                        <li>The right to know. You have the right to request: (i) the specific pieces of Personal Information we have about you; (ii) the categories of Personal Information we have collected about you in the last 12 months; (iii) the categories of sources from which that Personal Information was collected; (iv) the categories of your Personal Information that we sold or disclosed in the last 12 months; (v) the categories of third parties to whom your Personal Information was sold or disclosed in the last 12 months; and (vi) the purpose for collecting and selling Personal Information.</li>
                        <li>The right to deletion. You have the right to request that we delete the Personal Information that we have collected or maintain about you. We may deny your request under certain circumstances, such as if we need to comply with our legal obligations or complete a transaction for which your Personal Information was collected. Certain exceptions under the California law may allow AccidentMATE to retain and use certain Personal Information notwithstanding your deletion request. If we deny your request for deletion, we will let you know the reason why.</li>
                        <li>The right to opt out of the sale of your Personal Information. You have the right to opt out of the sale of your Personal Information. AccidentMATE does not sell your Personal Information.</li>
                        <li>The right to equal service. If you choose to exercise any of these rights, AccidentMATE will not discriminate against you in anyway. If you exercise certain rights, understand that you may be unable to use or access certain features of AccidentMATE’ Services.</li>
                    </ul>
                    <p>You may exercise your right to know and your right to deletion twice a year free of charge. To exercise your right to know or your right to deletion, contact us at 1-888-982-1882 (for AccidentMATE customers) or email us at privacy@AccidentMATE.com.</p>
                    <p>We will take steps to verify your identity before processing your request to know or request to delete. We will not fulfill your request unless you have provided sufficient information for us to reasonably verify you are the individual about whom we collected Personal Information. If you have an account with us, we will use our existing account authentication practices to verify your identity. If you do not have an account with us, we may request additional information about you to verify your identity. We will only use the Personal Information provided in the verification process to verify your identity or authority to make a request and to track and document request responses, unless you initially provided the information for another purpose.</p>
                    <p>You may use an authorized agent to submit a request to know or a request to delete. When we verify your agent’s request, we may verify both your and your agent’s identity and request a signed document from you that authorizes your agent to make the request on your behalf. To protect your Personal Information, we reserve the right to deny a request from an agent that does not submit proof that they have been authorized by you to act on their behalf.</p>
                    <p>Shine the Light – Third Party Marketing. You may request and obtain from AccidentMATE once per calendar year information about any of your Personal Information shared with third parties for their own direct marketing purposes, including the categories of information and the names and addresses of those businesses with which we have shared such information. To request this information and for any other questions about our privacy practices and compliance with California law, please contact us at privacy@AccidentMATE.com.</p>
                    <h5 className='fw-semibold'>5. CONTACT INFORMATION</h5>
                    <p>We hope this Notice has been helpful in explaining how we handle your Personal Information. If you have any questions that we have not answered, please contact our Data Protection Officer who will be pleased to help you:</p>

                    <span>AccidentMATE <br />
                        Pranava Inc. <br />
                        Attn: Privacy Officer <br />
                        2160 Barranca Parkway <br />
                        # 1014, Irvine, CA 92606 <br />
                        privacy@AccidentMATE.com</span>

                    <h5 className='fw-semibold'>6. UPDATES</h5>
                    <p>We may update this Notice from time to time. When we update this Notice, we will post the changes on our Services and update the “Last Updated” date at the top of this page. We encourage you to check this Notice regularly for changes. If we make any material changes to this Notice, we will notify you before they take effect either through the Services or by sending you a notification. Unless otherwise noted, any changes we make to this Notice will become effective immediately once posted on this page. Your continued use of the Services following any changes to this Notice indicates that you have read and understood the practices described in the revised Notice.</p>

                    <Link to="/privacy-policy">Back to Privacy Policy</Link>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Cprivacy
