import React, { useEffect, useState } from 'react';
import client from '../../sanity';

import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import Query from "../../Components/Query/Query";
import Newsletter from "../../Components/Newsletter/Newsletter";


import BlockContentSerializer from '../../Components/BlockContentSerializer';
const Singleblog = (props) => {

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const query = '*[_type == "post"]';

      try {
        const result = await client.fetch(query);
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      
      <Navbar />
      <div className='single-blog'>
        <h1>Blogs</h1>
      </div>
  <div className='mt-5'>
      {
        data.map((item, index) => (
          (localStorage.getItem('itemId') == index) ?
          
            <div className='container singleblog'>
              <div className='row'>
                <div className='col-md-12'>
                  <h2 className='text-center'><b>{item.title}</b></h2>
                  <BlockContentSerializer blocks={item.body} />
                </div>
              </div>
            </div> :
            <>
            </>
        ))
      }
      </div>
      <Query />
      <Newsletter />
      <Footer />
    </div>
  )
}

export default Singleblog;
