import React, { useEffect, useState } from 'react';
import Blog1 from "../../assets/img/team/f1.png";
import Blog2 from "../../assets/img/team/f2.png";
import Blog3 from "../../assets/img/team/f3.png";
import client from "../../sanity"
import { Link } from 'react-router-dom';
const Content = () => {

  
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchedData = async () => {
      const query = '*[_type == "post"]{..., "mainImageUrl": mainImage.asset->url}';

      try {
        const result = await client.fetch(query);
        setData(result)
      }
      catch (error) {
        console.log(error);
      }

    };
    fetchedData();
  }, [])

  return (
    <div>
      <section id="featured" class="featured">
        <div className='blog-main'>
        <div className='row m-3'><h2 className='text-center '><b>Blogs </b></h2></div>
        </div>
      <div class="container">
    
        <div class="row" data-aos="fade-left">
        {
          data.map((item, index)=>(
            <div class="col-lg-4 col-md-4 mb-4 mt-4">
              <Link target="_blank" to={`/news/${item.slug.current}`} onClick={() => localStorage.setItem('itemId', index)}>
            <div class="member h-100" data-aos="zoom-in" data-aos-delay="100">
              <div class=""><img src={item.mainImageUrl} class="img-fluid" alt="Blog-1" /></div>
              <div class="member-info">
                <h4>{item.title}</h4>
                <Link target="_blank" to={`/news/${item.slug.current}`} onClick={() => localStorage.setItem('itemId', index)} target="_blank">Read More <i class="bi bi-arrow-right"></i></Link>
              </div>
            </div>
            </Link>
          </div>
          ))
        }

        </div>
      </div>
    </section> 
    </div>
  )
}

export default Content