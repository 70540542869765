import React from 'react'
import Layer1 from "../../assets/images/Icon/layer-2.png";
import Layer2 from "../../assets/images/Icon/Layer-1.png";
import Layer3 from "../../assets/images/Icon/layer-3.png";
import Layer4 from "../../assets/images/Icon/layer-4.png";
import Layer5 from "../../assets/images/Icon/layer-5.png";
import Layer6 from "../../assets/images/Icon/layer-6.png";


import { Icon } from '@iconify/react';

const Section2 = () => {
    return (
        <div>
           <div className="container mt-4">
          <div className='row'>
            <div className='col-lg-4 col-md-6 col-sm-12 '>
              <div className='section2__item'>
                <div className='d-flex justify-content-between pb-5' >

                  <img className='section2__icon1' src={Layer1}></img>
                 
                </div>
                <h4> Expand your Medical and Legal Network</h4>
                <p>Discover and build your Med-Legal network with specialized medical providers and experienced attorneys.</p>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-12 '>
              <div className='section2__item'>
                <div className='d-flex justify-content-between pb-5' >

                  <img className='section2__icon1' src={Layer2}></img>
                 
                </div>
                <h4>Multi-Channel Client Referrals</h4>
                <p>Source referrals from the AccidentMATE website, chiropractors, doctors, other attorneys and satisfied clients. </p>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-12 '>
              <div className='section2__item'>
                <div className='d-flex justify-content-between pb-5' >

                  <img className='section2__icon1' src={Layer3}></img>
                 
                </div>
                <h4>Instant/up-to-date access to Medical Records</h4>
                <p>Get medical records delivered to your case file automatically through seamless OrganizeMED EHR Integration.</p>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-12 '>
              <div className='section2__item'>
                <div className='d-flex justify-content-between pb-5' >

                  <img className='section2__icon1' src={Layer4}></img>
                 
                </div>
                <h4> Increase Case Value through value drivers captured in medical documentation</h4>
                <p>Level up to insurance software that searches for appropriate value drivers in medical documentation. </p>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-12 '>
              <div className='section2__item'>
                <div className='d-flex justify-content-between pb-5' >

                  <img className='section2__icon1' src={Layer5}></img>
 
                </div>
                <h4> Dedicated Client App Customized to your Practice</h4>
                <p>Gather all accident information, and get office documents & retainer signed instantly, all through one client app customized for your law practice. </p>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12 '>
              <div className='section2__item'>
                <div className='d-flex justify-content-between pb-5' >
                  <img className='section2__icon1' src={Layer6}></img>
                </div>
                <h4> Secure, Customizable, Compliant</h4>
                <p>Google Cloud hosted, secure, HIPAA-complaint platform with Aes-256-bit encryption and high data security. </p>
              </div>
            </div>
          </div>
        </div>
      
        </div>
    )
}

export default Section2
