import React, { useEffect, useState } from 'react';
import BlogImage from "../../assets/img/blog/blog-1.jpg"
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import client from "../../sanity"
const Blog = () => {

  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchedData = async () => {
      const query = '*[_type == "post"]{..., "mainImageUrl": mainImage.asset->url}';

      try {
        const result = await client.fetch(query);
        setData(result)
      }
      catch (error) {
        console.log(error);
      }

    };
    fetchedData();
  }, [])




  return (
    <div>

      <div>
        <section id="blog" className="blog">
          <div className="container">
            <div className="text-center" data-aos="fade-down">
              <h2 className="fw-semibold text-center  mb-4">Information that can help you in difficult times. </h2>
            </div>
            <div className="row" data-aos="fade-left">

              {
                data.slice(0, 3).map((item, index) => (
                  <div className="col-lg-4 col-md-4"  >
                    <Link target="_blank" to={`/news/${item.slug.current}`} onClick={() => localStorage.setItem('itemId', index)} >
                      <div className="member1 h-100" data-aos="zoom-in" data-aos-delay="100">
                        <div className=""><img src={item.mainImageUrl} className="img-fluid" alt="Blog image1" />
                        </div>
                        <div className="member-info">
                          <h4>{item.title}</h4>
                          <br />

                          <Link target="_blank" to={`/news/${item.slug.current}`} onClick={() => localStorage.setItem('itemId', index)}>Read More <Icon icon="bi:arrow-right-short" color='#e33b3f' /></Link>
                          <div className='' style={{ display: "none" }}>
                          </div>
                        </div>
                      </div>
                    </Link>

                  </div>
                ))
              }


            </div>
            <div className="text-center mt-5">
              <Link className="btn  view-btn px-5" to="/news" style={{ color: "white", border: "none" }}>View All Blogs</Link>
            </div>
          </div>
        </section>
      </div>

    </div>
  )
}

export default Blog