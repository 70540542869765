import React from 'react'
import { Link } from 'react-router-dom'
import videoimage from "../../assets/videobanner.png"
import red from "../../assets/red.png"
import video from "../../assets/video1.mp4"
import { Icon } from '@iconify/react';
const Video = () => {


  const toggle = () => {
    const trailer = document.querySelector(".trailer");
    trailer.classList.toggle("active");
    
    const video = document.querySelector("video");
    video.pause();
    video.currentTime = 0;
  }
  

  return (
    <div >
        <section id="video-box" className="mt-3">
          <div className='container video-inner'>


    <div className='row videoimage '>
  <img src={videoimage}    onClick={toggle} className='img-fluid videoimage1'></img>
  <img src={red} className='img-fluid videoimage2' onClick={toggle} ></img>
    </div>

    <div className='row text-center  mt-3' >
    <span style={{color:" #dad8d8"}}><b>OrganizeLAW</b> <br />A solution built to streamline the med-legal system and increase your referrals.</span>

    </div>
      
      <div className='trailer'>
        <video controls="true" src={video}></video>
        
        <Icon className='videoclose ' onClick={toggle} icon="emojione-monotone:multiply" color="white" width="36" height="36" />
      </div>
      </div>
    </section>
    </div>
  )
}

export default Video